import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"

const Reintervention = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Réintervention" />
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Réintervention
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          style={{ marginBottom: 20 }}
        >
          <div>
            Une seconde procédure peut être envisagée, après discussion
            pluridisciplinaire, en cas d’échec ou de dysfonctionnement du
            montage chirurgical. L’échec peut être défini par une perte de poids
            jugée insuffisante par le patient et par l’équipe soignante à long
            terme, en fonction du contexte somatique et psychologique.
          </div>
          <br />
          <div>
            Dans ce cas, l’IMC à prendre en compte est l’IMC maximal documenté.
            Un IMC inférieur à 35 kg/m2 ne contre-indique pas la réintervention.
          </div>
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default Reintervention
